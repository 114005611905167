define("discourse/plugins/discourse-chat-integration/admin/components/modal/channel-error", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal @closeModal={{@closeModal}} id="chat_integration_error_modal">
    <h4>{{i18n @model.channel.error_key}}</h4>
    <pre>{{@model.channel.error_info}}</pre>
  </DModal>
  */
  {
    "id": "zk3CWBY0",
    "block": "[[[8,[39,0],[[24,1,\"chat_integration_error_modal\"]],[[\"@closeModal\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[10,\"h4\"],[12],[1,[28,[35,1],[[30,2,[\"channel\",\"error_key\"]]],null]],[13],[1,\"\\n  \"],[10,\"pre\"],[12],[1,[30,2,[\"channel\",\"error_info\"]]],[13],[1,\"\\n\"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-chat-integration/admin/components/modal/channel-error.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)(undefined, "channel-error"));
});